export const permissionData = {
  "analysis": {
    "feature": "Analysis",
    "screen": {
      "detail_vc": "Detail VC",
      "summary_vc": "Summary VC"
    },
    "action": {
      "save": "Save",
      "view": "View"
    },
    "rowItems": {
      "detail_vc": {
        "availableColumns": {
          "discipline_area": "Discipline Area",
          "product_category": "Product Category",
          "product_sub_category": "Product Sub Category",
          "hs_code": "HS Code",
          "name": "Name",
          "uom": "UOM",
          "percentage": "Percentage",
          "qty": "Qty",
          "savings": "Savings",
          "selected": "Selected",
          "selected_total_price": "Selected Total Price",
          "selected_unit_price": "Selected Unit Price",
          "spec_id": "Spec ID",
          "total_price": "Total Price",
          "total_price_base": "Total Price Base",
          "unit_price": "Unit Price",
          "unit_price_base": "Unit Price Base",
          "vendor_spec": "Vendor Spec",
          "internal_comments": "Internal Comments"
        },
      },
      "summary_vc": {
        "availableColumns": {}
      }
    }
  },
  "boq_management": {
    "feature": "BoQ Management",
    "screen": {
      "boq_listing": "BOQ Listing",
      "ffne_boq_details": "FF&E BoQ Details",
      "specifications": "Specifications"
    },
    "action": {
      "create": "Create",
      "delete": "Delete",
      "edit": "Edit",
      "view": "View",
      "add_masters": "Add Masters",
      "approval": "Approval",
      "client_view": "Client View",
      "create_versions": "Create Versions",
      "downloads": "Downloads",
      "release_edit_lock": "Release Edit Lock",
      "send_boq_to_user": "Send BoQ to User",
      "view_history": "View History",
      "save_markup": "Save Markup",
      "upload_spec": "Upload Spec",
    },
    "rowItems": {
      "boq_listing": {
        "availableColumns": {
          "action": "Action",
          "boq_ref_no": "BoQ Ref No",
          "last_updated_at": "Last Updated At",
          "last_updated_by": "Last Updated By",
          "status": "Status"
        }
      },
      "ffne_boq_details": {
        "availableColumns": {
          "action": "Action",
          "area_quantity": "Area Quantity",
          "discipline_area": "Discipline - Area",
          "variant": "Variant",
          "finish_category": "Finish Category",
          "finish_category_type": "Finish Category Type",
          "hs_code": "HS Code",
          "item_name": "Item Name",
          "multiplier": "Multiplier",
          "product_category": "Product Category",
          "Product_sub_category": "Product Sub-Category",
          "spec_id": "Spec ID",
          "stage": "Stage",
          "status": "Status",
          "total_finish_quantity": "Total Finish Quantity",
          "total_item_quantity": "Total Item Quantity",
          "uom": "UOM"
        }
      },
      "specifications": {
        "availableColumns": {
          "spec_id": "Spec ID",
          "name": "Name",
          "product_sub_category": "Product Sub-Category",
          "specification": "Specification",
          "brand_mfr": "Brand/Mfr.",
          "distributor": "Distributor",
          "uom_id": "UOM",
          "action": "Action",
        }
      }
    }
  },
  "budget_management": {
    "feature": "Budget Management",
    "screen": {
      "detailed_budget": "Detailed Budget",
      "open_items": "Open Items",
      "summary_budget": "Summary Budget"
    },
    "action": {
      "approve": "Approve",
      "comment": "Comment",
      "download": "Download",
      "view": "View",
      "send_to_client": "Send To Client",
    },
    "rowItems": {
      "detailed_budget": {
        "availableColumns": {
          "C_and_D": "C&D",
          "category_sub_category": "Category/Sub-Category",
          "cif": "CIF",
          "discipline_area": "Discipline-Area",
          "duties": "Duties",
          "ex_works": "Ex Works",
          "finish_category": "Finish Category",
          "finish_type": "Finish Type",
          "hs_code": "HS Code",
          "installation_ddp": "Installation/DDP",
          "item_name": "Item Name",
          "markup": "Markup",
          "others": "Others",
          "spec_id": "Spec ID",
          "total": "Total",
          "total_finish_qty": "Total Finish Qty",
          "total_item_qty": "Total Item Qty",
          "unit_price": "Unit Price",
          "uom": "UOM",
          "vendor": "Vendor",
          "warehouse": "Warehouse",
          "vc_comments": "VC Comments",
        }
      },
      "open_items": {
        "availableColumns": {
          "action": "Action",
          "discipline_area": "Discipline-Area",
          "variant": "Variant",
          "item_name": "Item Name",
          "product_category": "Product Category",
          "product_sub_category": "Product Sub-Category",
          "stage": "Stage",
          "total_qty": "Total Qty",
          "uom": "UOM",
          "spec_id": "Spec ID"
        }
      },
      "summary_budget": {
        "availableColumns": {
          "approved": "Approved",
          "cif": "CIF",
          "clearance_and_delivery": "Clearance and Delivery",
          "duties": "Duties",
          "installation_ddp": "Installation/DDP",
          "markup": "Markup",
          "other": "Other",
          "package": "Package",
          "profit_margin": "Profit Margin",
          "rfp_name": "RFP Name",
          "sub_total": "Sub Total",
          "taxes": "Taxes",
          "total": "Grand Total",
          "total_vendor_bid": "Total Vendor Bid",
          "vendor_bid": "Vendor Bid",
          "warehouse": "Warehouse",
        }
      }
    }
  },
  "client_administration": {
    "feature": "Client Administration",
    "screen": {
      "client_listing": "Client Listing"
    },
    "action": {
      "create": "Create",
      "create_pqf": "Create PQF",
      "delete": "Delete",
      "edit": "Edit",
      "view": "View",
    },
    "rowItems": {
      "client_listing": {
        "availableColumns": {
          "action": "Action",
          "client_name": "Client Name",
          "email": "Email",
        }
      }
    }
  },
  "designer_administration": {
    "feature": "Designer Administration",
    "screen": {
      "designer": "Designer"
    },
    "action": {
      "create": "Create",
      "delete": "Delete",
      "edit": "Edit",
      "view": "View",
    },
    "rowItems": {
      "designer": {
        "availableColumns": {
          "action": "Action",
          "country": "Country",
          "designer_name": "Designer Name",
        }
      }
    }
  },
  "division_management": {
    "feature": "Division Management",
    "screen": {
      "division": "Division"
    },
    "action": {
      "create": "Create",
      "delete": "Delete",
      "edit": "Edit",
      "view": "View",
    },
    "rowItems": {
      "division": {
        "availableColumns": {
          "action": "Action",
          "currency_code": "Currency Code",
          "name": "Name",
        }
      }
    }
  },
  "enquiry_management": {
    "feature": "Enquiry Management",
    "screen": {
      "enquiry": "Enquiry",
      "enquiry_form": "Enquiry Form"
    },
    "action": {
      "delete": "Delete",
      "update_status": "Update Status",
      "save": "Save",
      "view": "View",
      "send_email": "Send Email",
    },
    "rowItems": {
      "enquiry": {
        "availableColumns": {
          "no_of_keys": "# of Keys",
          "action": "Action",
          "company_name": "Company Name",
          "country": "Country",
          "project_name": "Project Name",
          "received_at": "Received At",
          "status": "Status"
        }
      },
      "enquiry_form": {
        "availableColumns": {}
      }
    }
  },
  "master_data_administration": {
    "feature": "Master Data Administration",
    "screen": {
      "allowance_agreement": "Allowance Agreement",
      "area": "Area",
      "currency": "Currency",
      "discipline": "Discipline",
      "document_type": "Document Type",
      "finish_category": "Finish Category",
      "finish_category_type": "Finish Category Type",
      "formula_builder": "Formula Builder",
      "product_category": "Product Category",
      "product_sub_category": "Product Sub-Category",
      "tax_type": "Tax Type",
      "category_markup": "Category Markup"
    },
    "action": {
      "edit": "Edit",
      "view": "View",
      "create": "Create",
      "delete": "Delete",
    },
    "rowItems": {
      "allowance_agreement": {
        "availableColumns": {}
      },
      "area": {
        "availableColumns": {
          "action": "Action",
          "area_name": "Area Name",
          "discipline_name": "Discipline Name",
          "status": "Status"
        }
      },
      "currency": {
        "availableColumns": {
          "action": "Action",
          "code": "Code",
          "currency_name": "Currency Name",
          "status": "Status",
          "symbol": "Symbol"
        }
      },
      "discipline": {
        "availableColumns": {
          "action": "Action",
          "discipline_name": "Discipline Name",
          "status": "Status"
        }
      },
      "document_type": {
        "availableColumns": {
          "action": "Action",
          "areas": "Areas",
          "bulk_uploads": "Bulk Uploads",
          "document_type": "Document Type",
          "source": "Source",
          "status": "Status"
        }
      },
      "finish_category": {
        "availableColumns": {
          "action": "Action",
          "finish_category_name": "Finish Category Name",
          "status": "Status"
        }
      },
      "finish_category_type": {
        "availableColumns": {
          "action": "Action",
          "finish_category_name": "Finish Category Name",
          "finish_category_type_name": "Finish Category Type Name",
          "status": "Status"
        }
      },
      "formula_builder": {
        "availableColumns": {}
      },
      "product_category": {
        "availableColumns": {
          "action": "Action",
          "business_unit_type": "Business Unit Type",
          "product_category_name": "Product Category Name",
          "status": "Status"
        }
      },
      "product_sub_category": {
        "availableColumns": {
          "action": "Action",
          "business_unit_type": "Business Unit Type",
          "product_category_name": "Product Category Name",
          "product_sub_category_name": "Product Sub-Category Name",
          "status": "Status"
        }
      },
      "tax_type": {
        "availableColumns": {
          "action": "Action",
          "rate": "Rate(%)",
          "status": "Status",
          "tax_type": "Tax Type"
        }
      },
      "category_markup": {
        "availableColumns": {
          "product_sub_category": "Product Sub-Category",
          "markup": "Markup %",
          "cif": "CIF %",
          "installation": "Installation %",
          "duty": "Duty %",
          "clearance_and_delivery": "Clearance & Delivery %",
          "warehouse": "Warehouse %",
          "other": "Other %",
          "action": "Action"
        }
      }
    }
  },
  "operator_administration": {
    "feature": "Operator Administration",
    "screen": {
      "operator": "Operator"
    },
    "action": {
      "create": "Create",
      "delete": "Delete",
      "edit": "Edit",
      "view": "View",
    },
    "rowItems": {
      "operator": {
        "availableColumns": {
          "action": "Action",
          "country": "Country",
          "operator_name": "Operator Name",
        }
      }
    }
  },
  "project_information": {
    "feature": "Project Information",
    "screen": {
      "banquets": "Banquets",
      "bill_to_ship_to_adress": "Bill To/Ship To Address",
      "document_register": "Document Register",
      "drawing_and_layouts": "Drawing & Layouts",
      "fnb_restaurents": "F&B Restaurants",
      "ffne_file_uploads": "FF&E File Uploads",
      "input_matrix": "Input Matrix",
      "osne_file_uploads": "OS&E File Uploads",
      "others": "Others",
      "project_logistics": "Project Logistics",
      "recreational_areas": "Recreational Areas",
      "specification_and_finishes": "Specification & Finishes",
      "stakeholder_information": "Stakeholder Information"
    },
    "action": {
      "save": "Save",
      "view": "View",
      "edit": "Edit",
      "upload": "Upload",
    },
    "rowItems": {
      "banquets": {
        "availableColumns": {}
      },
      "bill_to_ship_to_adress": {
        "availableColumns": {}
      },
      "document_register": {
        "availableColumns": {
          "area": "Area",
          "detail_drawing": "Detail Drawing",
          "drawing_register": "Drawing Register",
          "elevation_drawing": "Elevation Drawing",
          "ff&e_specifications": "FF&E Specifications",
          "finish_specifications": "Finish Specifications",
          "furniture_layout_plan": "Furniture Layout Plan",
          "rendering": "Rendering",
        }
      },
      "drawing_and_layouts": {
        "availableColumns": {}
      },
      "fnb_restaurents": {
        "availableColumns": {}
      },
      "ffne_file_uploads": {
        "availableColumns": {}
      },
      "input_matrix": {
        "availableColumns": {
          "action": "Action",
          "bath_count": "Bath Count",
          "bed_count": "Bed Count",
          "bed_count": "Bed Count",
          "comments": "Comments",
          "covers_chairs": "Covers/Chairs",
          "full_double": "Full/Double",
          "king": "King",
          "kitchen_count": "Kitchen Count",
          "living_count": "Living Count",
          "outlet_name": "Outlet Name",
          "outlet_type": "Outlet Type",
          "powder_count": "Powder Count",
          "queen": "Queen",
          "room_type_name": "Room Type Name",
          "sofa_bed": "Sofa Bed",
          "super_king": "Super King",
          "twin": "Twin",
          "twin_xl": "Twin XL",
          "units": "Units"
        }
      },
      "osne_file_uploads": {
        "availableColumns": {}
      },
      "others": {
        "availableColumns": {}
      },
      "project_logistics": {
        "availableColumns": {}
      },
      "recreational_areas": {
        "availableColumns": {}
      },
      "specification_and_finishes": {
        "availableColumns": {}
      },
      "stakeholder_information": {
        "availableColumns": {}
      }
    }
  },
  "project_management": {
    "feature": "Project Management",
    "screen": {
      "category_markup": "Category Markup",
      "details": "Details",
      "file_ploads": "File Uploads",
      "project_list": "Project List",
      "specifications": "Specifications",
      "taxes": "Taxes"
    },
    "action": {
      "create": "Create",
      "delete": "Delete",
      "edit": "Edit",
      "view": "View",
      "create_versions": "Create Versions",
      "save_markup": "Save Markup",
      "upload_spec": "Upload Spec",
      "view_history": "View History",
    },
    "rowItems": {
      "category_markup": {
        "availableColumns": {
          "action": "Action",
          "cif": "CIF %",
          "clearance_and_delivery": "Clearance & Delivery %",
          "duty": "Duty %",
          "installation": "Installation %",
          "markup": "Markup %",
          "other": "Other %",
          "product_sub_category": "Product Sub-Category",
          "warehouse": "Warehouse %",
        }
      },
      "details": {
        "availableColumns": {}
      },
      "file_ploads": {
        "availableColumns": {
          "action": "Action",
          "attached_document_name": "Attached Document Name",
          "comments": "Comments",
          "date_received": "Date Received",
          "document_type": "Document Type",
        }
      },
      "project_list": {
        "availableColumns": {}
      },
      "specifications": {
        "availableColumns": {
          "action": "Action",
          "brand_mfr": "Brand/Mfr.",
          "can_vendor_price": "Can Vendor Price?",
          "distributor": "Distributor",
          "finish_type": "Finish Type",
          "name": "Name",
          "product_category": "Product Category",
          "product_sub_category": "Product Sub-Category",
          "spec_id": "Spec ID",
          "specification": "Specification",
          "uom_id": "uom_id"
        }
      },
      "taxes": {
        "availableColumns": {
          "action": "Action",
          "rate": "Rate(%)",
          "tax_type": "Tax Type"
        }
      },
    }
  },
  "rfp": {
    "feature": "RFP",
    "screen": {
      "create_rfps": "Create RFPs",
      "detail_costing": "Detail Costing",
      "issue_rfps": "Issue RFPs"
    },
    "action": {
      "create": "Create",
      "view": "View",
      "comment": "Comment",
      "download": "Download",
      "save": "Save",
      "upload": "Upload",
      "approve": "Approve",
      "issue": "Issue",
    },
    "rowItems": {
      "create_rfps": {
        "availableColumns": {
          "category": "Category",
          "comments": "Comments",
          "discipline_area": "Discipline-Area",
          "hs_code": "HS Code",
          "item_name": "Item Name",
          "qty": "Qty",
          "spec_id": "Spec ID",
          "sub_category": "Sub-Category",
          "uom": "UOM"
        }
      },
      "detail_costing": {
        "availableColumns": {
          "action": "Action",
          "discipline_area": "Discipline-Area",
          "finish_category": "Finish Category",
          "finish_qty": "Finish Qty",
          "finish_type": "Finish Type",
          "hs_code": "HS Code",
          "item_name": "Item Name",
          "product_category": "Product Category",
          "product_sub_category": "Product Sub Category",
          "spec_id": "Spec ID",
          "total_price": "Total Price",
          "total_qty": "Total Qty",
          "unit_price": "Unit Price",
          "unit_qty": "Unit Qty",
          "uom": "UoM",
          "vendor_spec": "Vendor Spec"
        }
      },
      "issue_rfps": {
        "availableColumns": {
          "category": "Category",
          "comments": "Comments",
          "discipline_area": "Discipline-Area",
          "hs_code": "HS Code",
          "item_name": "Item Name",
          "qty": "Qty",
          "spec_id": "Spec ID",
          "sub_category": "Sub-Category",
          "uom": "UoM"
        }
      },
    }
  },
  "template_administration": {
    "feature": "Template Administration",
    "screen": {
      "pqq": "PQQ"
    },
    "action": {
      "edit": "Edit",
      "view": "View"
    },
    "rowItems": {
      "pqq": {
        "availableColumns": {}
      }
    }
  },
  "user_administration": {
    "feature": "User Administration",
    "screen": {
      "roles_and_permissions": "Roles and Permissions",
      "user_listing": "User Listing"
    },
    "action": {
      "edit": "Edit",
      "save_as": "Save As",
      "view": "View",
      "create": "Create",
      "delete": "Delete",
    },
    "rowItems": {
      "roles_and_permissions": {
        "availableColumns": {}
      },
      "user_listing": {
        "availableColumns": {
          "action": "Action",
          "email": "Email",
          "name": "Name",
          "role": "Role",
          "user_id": "User ID",
          "status": "Status",
          "entity": "Entity"
        }
      }
    }
  },
  "vendor_administration": {
    "feature": "Vendor Administration",
    "screen": {
      "allowances": "Allowances",
      "details": "Details",
      "enquiries": "Enquiries",
      "file_uploaded": "File Uploaded",
      "listing": "Listing",
      "product_catalog": "Product Catalog",
      "vendor_pqq": "Vendor PQQ"
    },
    "action": {
      "create": "Create",
      "edit": "Edit",
      "view": "View",
      "approval": "Approval",
      "download": "Download",
      "send_email": "Send Email",
      "delete": "Delete",
      "save_selection": "Save Selection",
    },
    "rowItems": {
      "allowances": {
        "availableColumns": {}
      },
      "details": {
        "availableColumns": {}
      },
      "enquiries": {
        "availableColumns": {
          "action": "Action",
          "business_type": "Business Type",
          "company_name": "Company Name",
          "country": "Country",
          "date_time_received": "Date/Time Received",
          "email_id": "Email ID",
          "project_request": "Project Request"
        }
      },
      "file_uploaded": {
        "availableColumns": {
          "action": "Action",
          "document_type": "Document Type",
          "document_type_source": "Document Type Source",
          "file_size": "File Size",
          "uploaded_by": "Uploaded By",
          "uploaded_on": "Uploaded On"
        }
      },
      "listing": {
        "availableColumns": {
          "action": "Action",
          "allowance": "Allowance",
          "division": "Division",
          "location": "Location",
          "name": "Name",
          "score": "Score",
          "status": "Status",
          "vendor_type": "Vendor Type"
        }
      },
      "product_catalog": {
        "availableColumns": {
          "brand": "Brand",
          "country_of_manufacturing": "Country of Manufacturing",
          "distributor_product_#": "Distributor Product #",
          "hs_code": "HS Code",
          "item_name": "Item Name",
          "manufacturer_product_#": "Manufacturer Product #",
          "patter_model": "Pattern/Model",
          "ships_from": "Ships From",
          "specification": "Specification",
          "unit_price": "Unit Price",
          "uom": "UOM",
          "action": "Action",
        }
      },
      "vendor_pqq": {
        "availableColumns": {}
      },
    }
  },
  "vendor_administration_client": {
    "feature": "Vendor Administration - Client",
    "screen": {
      "vendor_request": "Vendor Request"
    },
    "action": {
      "approve": "Approve",
      "create": "Create",
      "view": "View"
    },
    "rowItems": {
      "vendor_request": {
        "availableColumns": {}
      }
    }
  },
  "osne_master_data": {
    "feature": "OS&E Master Data",
    "screen": {
      "product_catalog": "Product Catalog",
      "product_destination": "Product Destination",
      "boq_template": "BoQ Template",
      "multiplier_rules": "Multiplier Rules"
    },
    "action": {
      "create": 'Create',
      "delete": "Delete",
      "edit": "Edit",
      "view": "View",
      "approve": "Approve",
      "download": "Download"
    },
    "rowItems": {
      "product_catalog": {
        "availableColumns": {
          "itemName": "Item Name",
          "productId": "Product ID",
          "specification": "Specification",
          "brandOperator": "Brand/Operator",
          "tag": "User Identifier/Tag",
          "uom": "UoM",
          "price": "Price",
          "approved": "Approved",
          "action": "Action",
        }
      },
      "product_destination": {
        "availableColumns": {
          "5STAR": "5 Star",
          "4STAR": "4 Star",
          "3STAR": "3 Star",
          "discipline": "Discipline",
          "area": "Area/Sub-Area",
          "destination": "Destination",
          "category": "Category",
          "subCategory": "Sub Category",
          "itemName": "Item Name",
          "productId": "Product ID",
          "tag": "User Identifier/Tag",
          "specification": "Specification",
          "uom": "UoM",
          "price": "Price",
          "approved": "Approved",
          "action": "Action",
        }
      },
      "boq_template": {
        "availableColumns": {
          "productDestination": "Product Destination",
          "productCategory": "Product Category",
          "productSubCategory": "Product Sub Category",
          "itemName": "Item Name",
          "specification": "Specification",
          "productId": "Product ID",
          "tag": "User Identifier/Tag",
          "uom": "UoM",
          "vendorProductId": "Vendor Product ID",
          "vendorSpecification": "Vendor Specification",
          "estUnitPrice": "Est. Unit Price(USD)",
          "reference1": "Reference 1",
          "reference2": "Reference 2",
          "reference3": "Reference 3",
          "reference4": "Reference 4",
          "reference5": "Reference 5",
          "unitQty": "Unit Qty",
          "par": "Par",
          "hsCode": "HS Code",
          "approved": "Approved",
          "action": "Action"
        }
      },
      "multiplier_rules": {
        "availableColumns": {
          "discipline": "Discipline",
          "area": "Area",
          "currentMultiplierRule": "Multiplier Rule",
          "previousMultiplierRule": "Previous Rule",
        }
      }
    }
  },
  "capex_management": {
    "feature": "Capex",
    "screen": {
      "property_information_and_logistics": "Property Information - Logistics",
      "create_capex_request": "Create Request",
      "pending_capex_request": "Pending Requests",
      "approved_capex_request": "Approved Requests",
      "specifications": "View/Edit Request"
    },
    "action": {
      "edit": "Edit",
      "view": "View",
      "approve": "Approve",
    },
    "rowItems": {
      "property_information_and_logistics": {
        "availableColumns": {},
      },
      "create_capex_request": {
        "availableColumns": {},
      },
      "pending_capex_request": {
        "availableColumns": {
          "itemNumber": "#",
          "serialNumber": "Serial Number",
          "itemName": "Item Name",
          "budgetPrice": "Budget Price",
          "purchasePrice": "Purchase Price",
          "requestDate": "Request Date",
          "requestor": "Requestor",
          "status": "Status",
          "action": "Action"
        }
      },
      "approved_capex_request": {
        "availableColumns": {
          "itemNumber": "#",
          "serialNumber": "Serial Number",
          "itemName": "Item Name",
          "budgetPrice": "Budget Price",
          "purchasePrice": "Purchase Price",
          "requestDate": "Request Date",
          "requestor": "Requestor",
          "status": "Status",
          "action": "Action"
        }
      },
      "specifications": {
        "availableColumns": {}
      }
    }
  },
  "osne_boq_budget": {
    "feature": "OS&E BOQ & Budget",
    "screen": {
      "boq_listing": "BoQ Listing",
      "boq_budget": "BoQ Budget",
      "boq_summary": "BoQ Summary"
    },
    "action": {
      "create": 'Create',
      "delete": "Delete",
      "edit": "Edit",
      "view": "View",
      "download": "Download",
      "comment": "Comment",
      "approve": "Approve",
      "create_version": "Create Version",
      "submit": "Submit"
    },
    "rowItems": {
      "boq_listing": {
        "availableColumns": {
          "refNo": "BoQ Ref No",
          "lastUpdatedByName": "Last Updated By",
          "updatedAt": "Last Updated At",
          "status": "Status",
          "action": "Action",
        }
      },
      "boq_budget": {
        "availableColumns": {
          "productDestinationName": "Product Destination",
          "productCategory": "Product Category",
          "productSubCategory": "Product Sub-Category",
          "itemName": "Item Name",
          "productId": "Product ID",
          "specification": "Specification",
          "uom": "UoM",
          "vendorProductId": "Vendor Product ID",
          "vendorSpecification": "Vendor Specification",
          "price": "Est. Unit Price",
          "budgetUnitPrice": "Budget Unit Price",
          "reference1": "Reference 1",
          "reference2": "Reference 2",
          "reference3": "Reference 3",
          "reference4": "Reference 4",
          "reference5": "Reference 5",
          "unitQuantity": "Unit Qty",
          "multiplier": "Multiplier",
          "totalQty": "Total Qty",
          "par": "Par",
          "finalQty": "Final Qty",
          "finalCostPrice": "Final Cost Price",
          "finalBudgetPrice": "Final Budget Price",
          "status": "Status",
          "hsCode": "HS Code",
          "view": "View",
          "action": "Action",
        }
      },
      "boq_summary": {
        "availableColumns": {
          "summaryName": "Summary Name",
          "costPrice": "Cost Price",
          "budgetPrice": "Budget Price",
          "approved": "Approved",
          "budgetMetrics": "Budget Metrics",
          "currencyValue": "Budget Metrics Value"
        }
      }
    }
  },
}

export const PERMISSION_MODULES = {
  boqManagement: "boq_management",
  designerAdministration: "designer_administration",
  enquiryManagement: "enquiry_management",
  masterDataAdministration: "master_data_administration",
  operatorAdministration: "operator_administration",
  clientAdministration: "client_administration",
  divisionManagement: "division_management",
  projectManagement: "project_management",
  rfp: "rfp",
  templateAdministration: "template_administration",
  userAdministration: "user_administration",
  vendorAdministration: "vendor_administration",
  vendorAdministrationClient: "vendor_administration_client",
  analysis: "analysis",
  projectInformation: "project_information",
  budgetManagement: "budget_management",
  osneMasterData: "osne_master_data",
  osneBoQBudget: "osne_boq_budget",
  capexManagement: "capex_management"
}

export const PERMISSION_SCREEN = {
  discipline: "discipline",
  area: "area",
  productCategory: "product_category",
  productSubCategory: "product_sub_category",
  currency: "currency",
  tax_type: "tax_type",
  enquiry: "enquiry",
  enquiry_form: "enquiry_form",
  client_listing: "client_listing",
  operator: "operator",
  division: "division",
  designer: "designer",
  roles_and_permissions: "roles_and_permissions",
  user_listing: "user_listing",
  category_markup: "category_markup",
  details: "details",
  file_ploads: "file_ploads",
  project_list: "project_list",
  specifications: "specifications",
  taxes: "taxes",
  allowances: "allowances",
  enquiries: "enquiries",
  listing: "listing",
  vendor_pqq: "vendor_pqq",
  file_uploaded: "file_uploaded",
  pqq: "pqq",
  banquets: "banquets",
  bill_to_ship_to_adress: "bill_to_ship_to_adress",
  document_register: "document_register",
  drawing_and_layouts: "drawing_and_layouts",
  fnb_restaurents: "fnb_restaurents",
  ffne_file_uploads: "ffne_file_uploads",
  input_matrix: "input_matrix",
  osne_file_uploads: "osne_file_uploads",
  others: "others",
  project_logistics: "project_logistics",
  recreational_areas: "recreational_areas",
  specification_and_finishes: "specification_and_finishes",
  stakeholder_information: "stakeholder_information",
  boq_listing: "boq_listing",
  ffne_boq_details: "ffne_boq_details",
  specifications: "specifications",
  detail_costing: "detail_costing",
  create_rfps: "create_rfps",
  issue_rfps: "issue_rfps",
  summary_vc: "summary_vc",
  detail_vc: "detail_vc",
  detailed_budget: "detailed_budget",
  open_items: "open_items",
  summary_budget: "summary_budget",
  product_catalog: "product_catalog",
  product_destination: "product_destination",
  boq_template: "boq_template",
  property_information_and_logistics: "property_information_and_logistics",
  create_capex_request: "create_capex_request",
  pending_capex_request: "pending_capex_request",
  approved_capex_request: "approved_capex_request",
  boq_budget: "boq_budget",
  boq_summary: "boq_summary",
  multiplier_rules: "multiplier_rules",
}